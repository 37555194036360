<template>
  <div>
    <b-modal
        v-model="showCurrencyModal"
        id="currency_modal"
        centered
        modal-class="with-no-paddings"
        ok-only
        ok-title="Готово"
        :title="$t('modals.currency-conversion')"
        @hide="pickerWillHide"
    >

      <b-list-group flush style="max-height: 255px; overflow-y: scroll;">
        <b-list-group-item v-for="currency in Blockchain.tickers.listOf" :key="currency.ticker"
                           :active=" currency.ticker === User.currency " style="cursor: pointer;"
                           @click="pickCurrency(currency)">
          <div class="d-flex justify-content-between">
            <div>
              {{ currency.ticker }}
            </div>
            <div>
              {{ currency.value.formatMoney(2, ' ', ' ') }}
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>

    </b-modal>

    <div :class="(showPageUp && 'show-page-up ') + (isCartNotEmpty && ' open-cart-page-up')" class="page-up-button"
         @click="topScroll">
      <img alt="" src="~@/assets/images/site/icons/arrow_left.svg">
    </div>

    <Header/>

    <transition
        :name="routerTransition"
        mode="out-in"
    >
      <router-view/>
    </transition>

    <div
        :style="{
            position: 'fixed',
            bottom: 0,
            right: 0,
            zIndex: 9999,
            transitionProperty: 'all',
            transitionDuration: '.22s',
            left: '0',
            transitionProperty: 'all',
            transitionDuration: '.35s',
            transform: (summaryItemsInCart > 0) && ($route.name !== 'user-miners-checkout') ? 'translateY(0px)' : 'translateY(310px)'
        }">
      <cart-view :isVerticalMenuCollapsed="false"/>
    </div>

    <Footer class="mt-5"/>
  </div>
</template>

<script>
import Header from '@/views/Main/main-design-v2/components/Header.vue'
import Footer from "@/views/Main/main-design-v2/components/Footer";

import Blockchain from "@/modules/blockchain";
import miners from "@/modules/contents/miners";
import User from "@/modules/user/"
import useAppConfig from "@core/app-config/useAppConfig";
import CartView from "@/components/CartView"
import ModalForm from "@/views/Main/main-design-v2/components/ModalForm.vue"

export default {
  metaInfo() {
    const title = this.$t('title-pages.main');
    return {
      title: title,
      titleTemplate: null,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'Qubity - это платформа для майнинга криптовалют, где вы можете купить или арендовать хешрейт и начать зарабатывать до 150% годовых. В личном кабинете предоставляется мониторинг и аналитика ваших контрактов. На сайте представлен широкий ассортимент сертифицированного оборудования и различных инструментов для инвестирования'
        }
      ]
    }
  },
  setup() {
    const {
      routerTransition
    } = useAppConfig()

    return {
      routerTransition
    }
  },
  data() {
    return {
      Blockchain,
      User,
      miners,
      showCurrencyModal: false,
      canGoBack: false,
      showPageUp: false,
      prevScrollPosition: 0
    }
  },
  computed: {
    cart() {
      return Object.values(miners.cart);
    },
    summaryItemsInCart() {
      return this.cart.map(i => i.count).reduce((prev, curr) => prev + curr, 0)
    },
    isCartNotEmpty() {
      return Object.keys(miners.cart).length >= 1
    }
  },
  methods: {
    onScroll(e) {
      this.showPageUp = (window.pageYOffset - this.prevScrollPosition) < 0 && (window.pageYOffset !== 0);
      this.prevScrollPosition = window.pageYOffset
    },
    topScroll() {
      this.$smoothScroll({
        scrollTo: document.getElementById('header'),
        updateHistory: false,
        duration: 500
      })
    },
    pickCurrency(currency) {
      User.currency = currency.ticker;
    },
    pickerWillHide() {
      this.$bvModal.hide("currency_modal")
    },
    isHideMenu() {
      switch (this.currentRouteName()) {
        case 'payment-success':
          return true;
        case 'payment-error':
          return true;
        case 'payment-pending':
          return true;
        default:
          return false;
      }
    }
  },

  created() {
    window.addEventListener("scroll", this.onScroll)
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll)
  },
  components: {
    Header,
    Footer,
    CartView,
    ModalForm
  },
  watch: {
    "$route.query.act": function (action) {
      switch (action) {
        case 'currency':
          this.showCurrencyModal = true;
          return;
      }

      if (this.showCurrencyModal) {
        this.showCurrencyModal = false;
      }
    },
  }
}

</script>

<style lang="scss">
@import "~@/@core/scss/new-design";
@import "~@/@core/assets/fonts/euclid/euclid-font.css";

.page-up-button {
  background-color: var(--theme-accent-light);
  padding: 14px;
  display: flex;
  position: fixed;
  bottom: 230px;
  right: -110px;
  transition: all .3s ease;
  border-radius: 100px;
  cursor: pointer;
  z-index: 9;

  img {
    margin: auto;
    transform: rotate(90deg);
  }
}

.show-page-up {
  right: 16px;
}

@media screen and (min-width: 768px) {
  .open-cart-page-up {
    bottom: 310px;
  }
}

html[md="true"] {
  .mobile-cart-btn {
    background-color: var(--theme-accent-light) !important;
  }
}
</style>
