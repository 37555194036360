<template>
  <layout-n-d>
    <router-view />
  </layout-n-d>
</template>

<script>

import LayoutND from '@core/layouts/layout-new-design/LayoutND'

export default {
  components: {
    LayoutND,
  },
}
</script>


