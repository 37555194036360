var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"currency_modal","centered":"","modal-class":"with-no-paddings","ok-only":"","ok-title":"Готово","title":_vm.$t('modals.currency-conversion')},on:{"hide":_vm.pickerWillHide},model:{value:(_vm.showCurrencyModal),callback:function ($$v) {_vm.showCurrencyModal=$$v},expression:"showCurrencyModal"}},[_c('b-list-group',{staticStyle:{"max-height":"255px","overflow-y":"scroll"},attrs:{"flush":""}},_vm._l((_vm.Blockchain.tickers.listOf),function(currency){return _c('b-list-group-item',{key:currency.ticker,staticStyle:{"cursor":"pointer"},attrs:{"active":currency.ticker === _vm.User.currency},on:{"click":function($event){return _vm.pickCurrency(currency)}}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v(" "+_vm._s(currency.ticker)+" ")]),_c('div',[_vm._v(" "+_vm._s(currency.value.formatMoney(2, ' ', ' '))+" ")])])])}),1)],1),_c('div',{staticClass:"page-up-button",class:(_vm.showPageUp && 'show-page-up ') + (_vm.isCartNotEmpty && ' open-cart-page-up'),on:{"click":_vm.topScroll}},[_c('img',{attrs:{"alt":"","src":require("@/assets/images/site/icons/arrow_left.svg")}})]),_c('Header'),_c('transition',{attrs:{"name":_vm.routerTransition,"mode":"out-in"}},[_c('router-view')],1),_c('div',{style:({
          position: 'fixed',
          bottom: 0,
          right: 0,
          zIndex: 9999,
          transitionProperty: 'all',
          transitionDuration: '.22s',
          left: '0',
          transitionProperty: 'all',
          transitionDuration: '.35s',
          transform: (_vm.summaryItemsInCart > 0) && (_vm.$route.name !== 'user-miners-checkout') ? 'translateY(0px)' : 'translateY(310px)'
      })},[_c('cart-view',{attrs:{"isVerticalMenuCollapsed":false}})],1),_c('Footer',{staticClass:"mt-5"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }